export class LaboralAccionista {
    model = {
        id: { value: null, export: false },
        condicion_laboral: {
            value: null,
            header: 'Condición o relación Laboral',
            required: 'Seleccione una opción'
        },
        tipo_entidad: {
            value: null,
            header: 'Trabaja en una entidad',
            required: 'Seleccione una opción'
        },
        nombre_empresa: {
            value: '',
            header: 'Nombre de la empresa donde trabaja:',
            required: 'Campo requerido',
            maxLength: 'Máximo 150 caracteres',
            minLength: 'Mínimo 3 caracteres',
            max: 150,
            min: 3,
        },
        cargo: {
            value: '',
            header: 'Cargo/Función actual que desempeña:',
            required: 'Campo requerido',
            maxLength: 'Máximo 100 caracteres',
            minLength: 'Mínimo 3 caracteres',
            max: 100,
            min: 3,
        },
        sector_economico: {
            value: '',
            header: '*Sector Económico (Otros)',
            required: 'Campo requerido',
            maxLength: 'Máximo 100 caracteres',
            minLength: 'Mínimo 3 caracteres',
            max: 100,
            min: 3,
        },
        actividad_economica: {
            value: null,
            header: 'Actividad Económica',
            required: 'Seleccione una opción'
        },
        fecha_inicio_actividad: {
            value: new Date(),
            header: 'Inicio de actividades laborales',
            required: 'Fecha es requerido',
        },
        direccion_laboral: {
            value: '',
            header: 'Dirección Laboral: (Urbanización/barrio, calle, manzana, bloque, solar, villa)',
            required: 'Campo requerido',
            maxLength: 'Máximo 300 caracteres',
            minLength: 'Mínimo 3 caracteres',
            max: 300,
            min: 3,
        },
        correo_electronico_empresa: {
            value: '',
            header: 'Página web o correo electrónico de la empresa',
            required: 'Campo requerido',
            maxLength: 'Máximo 300 caracteres',
            minLength: 'Mínimo 3 caracteres',
            max: 300,
            min: 3,
        },
        es_pep: {
            value: null,
            header: 'y declaro bajo juramento que',
            required: 'Seleccione una opción',
        },
    }
    labelPep = <><b>Definiciones:</b><br />El Reglamento General a la Ley Orgánica de Prevención, Detección y Erradicación del Delito de Lavado de Activos y
        del Financiamiento de Delitos, establece como "<b>Personas Expuestas Políticamente (PEP)</b>.- Son todas aquellas personas naturales, nacionales o
        extranjeras, que desempeñan o han desempeñado funciones o cargos públicos destacados en el Ecuador o en el Extranjero; o funciones prominentes en
        una organización internacional, conforme a los lineamentos establecidos por la Unidad de Análisis Financiero y Económico (UAFE), y el organismo de
        control respectivo. Los familiares de las Personas Expuestas Políticamente (PEP), sean estos los cónyuges o personas unidas bajo el régimen de unión
        de hecho, familiares hasta el <b>segundo grado de consanguinidad o primero de afinidad</b>; y, el o <b>los colaborador (es) directo (s)</b> esto es
        la (s) persona (s) natural (es) con las cuales una Persona Expuesta Políticamente (PEP), se encuentre asociada o vinculada societariamente, o mantenga
        otro tipo de relaciones empresariales, comerciales o laborales estrechas, serán consideradas como PEP.<br /><br /><b>Declaración:</b></>
    dec2 = <>me encuentro ejerciendo uno de los cargos, o tengo una relación de las citadas en el párrafo anterior de esta sección..</>
    dec3 = <>En el caso de que su respuesta sea <b>positiva</b>, sírvase llenar el formulario de la última sección <b>(sección PEP)</b> e indicar: cargo/función, entidad donde
        desempeña tal función, o relación con la Persona Expuesta Políticamente.</>
    dec4 = <><b>Nota: Grados de consanguinidad</b> (1C-Padres, 1C-Hijos, 2C-Abuelos, 2C-Hermanos, 2C-Nietos.) <b>Grados de Afinidad</b> (1A Cónyuge, 1A-Suegros, 1A-Yerno/Nuera, 1A-Hijastro (a)).</>
    optionLabel = 'nombre';
}
