export class AccGeneral {

    model = {
        id: { value: null, export: false },
        fecha: {
            value: new Date(),
            header: 'Fecha:',
            required: 'Fecha es requerido',
        },
        pais: {
            value: null,
            header: 'País:',
            required: 'País es requerido'
        },
        provincia: {
            value: null,
            header: 'Provincia:',
            required: 'Provincia es requerido'
        },
        ciudad: {
            value: null,
            header: 'Ciudad:',
            required: 'Ciudad es requerida'
        },
        canton: {
            value: '',
            header: 'Ciudad:',
            maxLength: 'Máximo 100 caracteres',
            minLength: 'Mínimo 3 caracteres',
            max: 100,
            min: 3,
            required: 'Ciudad es requerida'
        },
        tipo_proceso: {
            value: null,
            header: 'Tipo de proceso:',
            required: 'Tipo de proceso es requerido'
        },
        tipo_relacion: {
            value: null,
            header: 'Relación/vinculación con BVG:',
            required: 'Seleccione una opción'
        },
        nombres: {
            value: '',
            header: 'Nombres completos',
            required: 'Nombres es requerido',
            maxLength: 'Máximo 300 caracteres',
            filterPlaceholder: 'nombres',
            minLength: 'Mínimo 3 caracteres',
            max: 300,
            min: 3,
        },
        apellidos: {
            value: '',
            header: 'Apellidos completos',
            required: 'Campo requerido',
            maxLength: 'Máximo 300 caracteres',
            filterPlaceholder: 'nombres',
            minLength: 'Mínimo 3 caracteres',
            max: 300,
            min: 3,
        },
        nacionalidad: {
            value: null,
            header: 'Nacionalidad',
            required: 'Nacionalidad es requerido'
        },
        tipo_identificacion: {
            value: null,
            required: 'Tipo de identificación es requerido',
            header: 'Tipo de identificación',
        },
        identificacion: {
            value: '',
            header: 'No. De Identificación',
            required: 'Identificación es requerido',
            maxLength: 'Máximo 50 caracteres',
            minLength: 'Mínimo 3 caracteres',
            max: 50,
            min: 3,
        },
        pais_residencia: {
            value: null,
            header: 'País de Residencia',
            required: 'País de Residencia es requerido'
        },
        estado_civil: {
            value: null,
            header: 'Estado Civil',
            required: 'Estado Civil es requerido'
        },
        genero: {
            value: null,
            header: 'Sexo',
            required: 'Seleccione una opción'
        },
        lugar_nacimiento: {
            value: '',
            header: 'Lugar de Nacimiento:',
            required: 'Campo es requerido',
            maxLength: 'Máximo 100 caracteres',
            minLength: 'Mínimo 3 caracteres',
            max: 100,
            min: 3,
        },
        fecha_nacimiento: {
            value: new Date(),
            header: 'Fecha de nacimiento:',
            required: 'Fecha es requerido'
        },
        direccion: {
            value: '',
            header: 'Dirección Domiciliaria o de Residencia: (Urbanización/barrio, calle, manzana, bloque, solar, villa)',
            required: 'Dirección es requerido',
            maxLength: 'Máximo 300 caracteres',
            minLength: 'Mínimo 3 caracteres',
            max: 300,
            min: 3,
        },
        telefono: {
            value: '',
            header: 'Teléfono/celular',
            required: 'Campo requerido',
            maxLength: 'Máximo 100 caracteres',
            minLength: 'Mínimo 3 caracteres',
            max: 100,
            min: 3,
        },
        profesion: {
            value: '',
            header: 'Profesión u Ocupación:',
            required: 'Apellidos es requerido',
            maxLength: 'Máximo 300 caracteres',
            minLength: 'Mínimo 3 caracteres',
            max: 300,
            min: 3,
        },
        correo_electronico: {
            value: '',
            header: 'Correo electrónico:',
            required: 'Campo requerido',
            maxLength: 'Máximo 300 caracteres',
            minLength: 'Mínimo 3 caracteres',
            max: 300,
            min: 3,
        },
    }

    optionLabel = 'nombre';
}
