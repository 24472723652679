export class Bienes {
    nota = <span style={{ textAlign: 'justify', fontStyle: 'italic', fontSize: '10px' }}><b>Nota:</b> Adjunte el estado de cuenta digital o cartilla correspondientes a
        los movimientos y saldos a la fecha de la revisión.</span>;
    notaBien = <span style={{ textAlign: 'justify', fontStyle: 'italic', fontSize: '10px' }}><b>REFERENCIAS A CONSIDERAR:</b><br /><b>No. De Predio </b>(Es el número o
        clave catastral asignado por el Municipio. En el comprobante de pago del impuesto predial puede obtener el número y el valor de la propiedad).<br />
        <b>Valor del Bien </b>(En ningún caso el valor del bien puede ser menor al avalúo comercial municipal. Si se trata de derechos y acciones, el valor será equivalente al porcentaje de participación).</span>
    strBVG = <span><p>Conocedor (a) de las disposiciones de la Ley Orgánica de Prevención, Detección y Erradicación del Delito de Lavado de Activos y del Financiamiento de Delitos y la Ley Orgánica de Protección de Datos Personales, autorizo y doy
        mi consentimiento libre, específico, informado e inequívoco, expresamente a BOLSA DE VALORES DE GUAYAQUIL S.A. BVG, a obtener, tratar y custodiar mis datos personales, sensibles y crediticios durante el tiempo establecido en las
        referidas leyes, conforme los principios de lealtad, transparencia, reserva y confidencialidad que las normativas citadas establecen y las que constan en la Política de Privacidad de la BOLSA DE VALORES DE GUAYAQUIL S.A.
        BVG, información que debe utilizarse únicamente para realizar los análisis y verificaciones que se considere necesarios para mi vinculación con la BOLSA DE VALORES DE GUAYAQUIL S.A. BVG; así como, informar a las
        autoridades competentes en caso de llegar a determinar la existencia de actividades, y/o transacciones Inusuales o Injustificadas. En virtud de lo antes autorizado, renuncio a instaurar por este motivo cualquier tipo de acción
        civil, penal o administrativa en contra de BOLSA DE VALORES DE GUAYAQUIL S.A. BVG o sus funcionarios y empleados.</p>
        <p>Conocedor (a) de las penas de perjurio, declaro bajo juramento que la información consignada en el presente documento y el origen de mis recursos son completamente lícitos, no provienen ni están relacionados con ninguna
        actividad u operación ilícita, o vinculados con negocios de lavado de dinero producto de narcotráfico de sustancias estupefacientes y psicotrópicas y que el destino de los fondos son y serán completamente lícitos, eximo a
        BOLSA DE VALORES DE GUAYAQUIL S.A. BVG, de toda responsabilidad, inclusive respecto a terceros, si esta declaración fuese falsa o errónea.</p>
        <p>En caso de que la información declarada pueda variar en el futuro, me comprometo a mantener informada y actualizada a BOLSA DE VALORES DE GUAYAQUIL S.A. BVG, proporcionándole la documentación e información que
        sea necesaria durante el tiempo que dure mi relación con esta.</p></span>
    decBVG = <span style={{ textAlign: 'justify', fontStyle: 'italic', fontSize: '10px', verticalAlign: 'bottom', fontWeight: 'bold' }}>EL FUNCIONARIO RESPONSABLE DEBERÁ INFORMAR AL DECLARANTE, QUE ESTE FORMULARIO ESTA BASADO EN LA POLÍTICA "CONOZCA A SU CLIENTE Y PROVEEDOR" DE LA NORMATIVA DE PREVENCIÓN, DETECCIÓN Y ERRADICACIÓN DEL DELITO DE
        LAVADO DE ACTIVOS Y DEL FINANCIAMIENTO DEL TERRORISMO Y OTROS DELITOS VIGENTE.</span>
    procBVG = <span style={{ textAlign: 'justify', fontStyle: 'italic', fontSize: '10px', verticalAlign: 'bottom', fontWeight: 'bold' }}>LOS DATOS PROPORCIONADOS SERÁN MANEJADOS CON ESTRICTA RESERVA Y CONFIDENCIALIDAD, EN CUMPLIMIENTO DE LO ESTIPULADO EN LA QUINTA DISPOSICIÓN GENERAL, DEL TITULO XXIV DE LA LEY DEL MERCADO DE VALORES-LIBRO SEGUNDO
        DEL CÓDIGO ORGÁNICO MONETARIO Y FINANCIERO REFERENTE AL SIGILO BURSÁTIL, EN CONCORDANCIA CON LA CUARTA DISPOSICION GENERAL Y CUARTO PÁRRAFO DEL ARTÍCULO 15, CAPÍTULO III, TÍTULO II DE LA LEY ORGÁNICA DE PREVENCIÓN,
        DETECCIÓN Y ERRADICACIÓN DEL DELITO DE LAVADO DE ACTIVOS Y DEL FINANCIAMIENTO DE DELITOS, Y TERCER PÁRRAFO DEL ARTÍCULO 25, CAPÍTULO III, TÍTULO I DEL REGLAMENTO GENERAL A LA LEY ORGÁNICA DE PREVENCIÓN, DETECCIÓN Y
        ERRADICACIÓN DEL DELITO DE LAVADO DE ACTIVOS Y DEL FINANCIAMIENTO DE DELITOS.</span>
    bgvAnexo = <span style={{ textAlign: 'justify', fontStyle: 'italic', fontSize: '11px', verticalAlign: 'bottom' }}><b>DOCUMENTACIÓN A ANEXAR:</b><br /><ul>
        <li>Cédula de Identidad o Ciudadanía/Pasaporte legibles del Cliente</li><li>Cédula de Identidad o Ciudadanía/Pasaporte legibles del Cónyuge e Hijos.</li>
        <li>Certificado de pago del impuesto a la renta del año inmediato anterior o constancia de la información publica del SRI.</li><li>Referencia Bancaria o Comercial, estados de cuenta y/o cartilla de movimientos.</li>
        <li>Registro Único de Contribuyentes, si aplica</li><li>Declaración de Origen y Destino Lícito de Recursos. Personas Naturales con dependencia económica de un tercero, se deberá anexar la documentacion de quien provenga los recursos.</li>
        <li>Recibo de cualquiera de los servicios básicos, no mayor a un mes de la dirección declarada como domicilio.</li></ul></span>
}
