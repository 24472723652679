import { useEffect, useRef, useState } from 'react'
import { useForm } from 'react-hook-form';
import { InputComponent } from '../../../components/InputComponent';
import { Panel } from 'primereact/panel';
import { Generico } from '../../../components/Generico';
import { FuncionarioService } from '../../../service/ficha/FuncionarioService';
import { LaboralAccionista } from '../../../models/fun/LaboralAccionista';
import { CatalogoService } from '../../../service/CatalogoService';
import { Divider } from 'primereact/divider';

export const InformacionLaboralAccionista = ({ generic, toast, id, permss, catalogos, handleNext, cliente, nuevoCliente, nextPrev }) => {

    const omodel = new LaboralAccionista();
    const defaultValues = generic.default(omodel.model, null);
    const { control, formState: { errors }, handleSubmit, reset, setError, getValues, setValue } = useForm({ defaultValues, shouldTrim: true });
    const accSrv = new FuncionarioService();
    const formRef = useRef(null);
    const { inDrop, inText, inCalendar, inAutocomplete } = InputComponent({ omodel, generic, errors, control, permss, id: getValues('id') });
    const { stringToDate } = Generico();
    const [selectedCiiu, setSelectedCiiu] = useState(null);
    const [ciius, setCiius] = useState([]);
    const [otros, setOtros] = useState(false);
    const catalogoSrv = new CatalogoService();

    const onChangePep = (e) => {
        nuevoCliente({ pep: e.value?.cat_id });
    }
    const [hayPep, setHayPep] = useState({ lab: omodel.optionLabel, chn: onChangePep });

    useEffect(() => {
        editFicha();
    }, [id]);

    const onSubmit = (data) => {
        data.id = id;
        data.seccion = "seccion3";
        data.fecha_inicio_actividad = data.fecha_inicio_actividad.toISOString().split('T')[0];
        data.otros = catalogos?.otros;
        accSrv.editar(data, 'accionista-pn-bvg').then(res => {
            handleNext();
            generic.funcSuccess(res, toast);
        }).catch(error => {
            generic.funcError(error, toast, setError);
        });
    }

    const editFicha = () => {
        accSrv.consultar(id, 'accionista-pn-bvg', 'seccion3').then(res => {
            const _cat = catalogos;
            const _dec = res.data.acc;
            if (!_dec) {
                reset(defaultValues);
                return;
            }
            const lista = {
                condicion_laboral: _cat.laboral,
                tipo_entidad: _cat.entidad,
                es_pep: generic.sino
            };
            setOtros(_dec.tipo_entidad === _cat?.otros?.cat_id);

            Object.keys(lista).forEach(function (key) {
                if (_dec[key] != null) {
                    lista[key].forEach(element => {
                        if (element.cat_id === _dec[key]) {
                            _dec[key] = element;
                        }
                    });
                }
            });
            if (_dec.fecha_inicio_actividad) _dec.fecha_inicio_actividad = stringToDate(_dec.fecha_inicio_actividad);

            const _pep = { lab: omodel.optionLabel, chn: onChangePep };
            if (_dec?.estado_pep) {
                _pep.ne = true;
            }
            setHayPep(_pep);

            reset(generic.renewValues(_dec, omodel.model));
        });
    }

    const onError = (errors) => {
        toast.current.show({ severity: 'error', summary: 'Error', detail: 'Se detectaron errores, por favor revise el formulario para corregir.', life: 3000 });
    }

    const searchCiiu = (event) => {
        setTimeout(() => {
            if (!event.query.trim().length) {
                setCiius([...ciius]);
            }
            else {
                catalogoSrv.getActividades(event.query.toLowerCase()).then(data => {
                    setCiius(data.data);
                });
            }
        });
    }

    const onChangeEntidad = (e) => {
        setOtros(e.value?.cat_id === catalogos?.otros?.cat_id);
        if(e.value?.cat_id !== catalogos?.otros?.cat_id) {
            setValue('sector_economico', '');
        }
    }

    return (
        <>
            <form ref={formRef} onSubmit={handleSubmit(onSubmit, onError)}>
                <Panel header="2.- Información Laboral:" className="panel-ficha">
                    <div className="formgrid grid">
                        {inDrop('condicion_laboral', catalogos?.laboral, { lab: omodel.optionLabel })}
                        {inDrop('tipo_entidad', catalogos?.entidad, { lab: omodel.optionLabel, chn: onChangeEntidad })}
                        {inText('nombre_empresa')}
                        {inText('cargo')}

                        {otros && inText('sector_economico')}
                        {inAutocomplete('actividad_economica', selectedCiiu, setSelectedCiiu, ciius, searchCiiu, { lab: 'nombre', col: 6 })}
                        {inCalendar('fecha_inicio_actividad')}
                        {inText('direccion_laboral')}

                        {inText('correo_electronico_empresa')}
                    </div>

                    {omodel.nota}
                </Panel>
                <br />

                <Panel header="3.- Persona Expuesta Políticamente o Públicamente (PEP´s)." className="panel-ficha">
                    {omodel.labelPep}
                    <div className="formgrid grid">
                        <div className="field col-12">
                            Yo, <b>{cliente?.nombre_completo}</b>, con documento de identificación No. <b>{cliente?.identificacion}</b>, he leído las definiciones de “Personas Expuestas Políticamente (PEP’s)”,
                        </div>
                        {inDrop('es_pep', generic.estadosSiNo, hayPep, 2)}
                        <div className="field col-10">
                            {omodel.dec2}
                        </div>
                    </div>
                    {omodel.dec3}
                    <Divider />
                    {omodel.dec4}
                </Panel>
                <br />

                {nextPrev()}
            </form>
        </>
    )
}
