export class Pep {

    model = {
        id: { value: null, export: false },
        idp: { value: null, export: false },
        nombre_completo: {
            value: '',
            export: false,
            header: 'Apellidos y Nombres Completos de los PEP´s:',
            table: true,
            filter: false,
            sort: true
        },
        cargo: {
            value: '',
            export: false,
            header: 'Cargo:',
            required: 'campo requerido',
            maxLength: 'Máximo 200 caracteres',
            minLength: 'Mínimo 3 caracteres',
            table: true,
            max: 200,
            min: 3,
            tag: true,
            mtaq: 'Registrar su formulario Pep (ingresar botón verde) ➜',
            severity: 'danger'
        },
        nombres: {
            value: '',
            header: 'Nombres completos',
            required: 'Nombres es requerido',
            maxLength: 'Máximo 300 caracteres',
            filterPlaceholder: 'nombres',
            minLength: 'Mínimo 3 caracteres',
            max: 300,
            min: 3,
        },
        apellidos: {
            value: '',
            header: 'Apellidos completos',
            required: 'Campo requerido',
            maxLength: 'Máximo 300 caracteres',
            filterPlaceholder: 'nombres',
            minLength: 'Mínimo 3 caracteres',
            max: 300,
            min: 3,
        },
    }
    optionLabel = 'nombre'
}
