import { useForm } from "react-hook-form";
import { Dialog } from "primereact/dialog";
import { confirmDialog } from "primereact/confirmdialog";
import { useEffect, useState } from "react";
import { FuncionarioService } from "../../../service/ficha/FuncionarioService";
import { Panel } from "primereact/panel";
import { InputComponent } from "../../../components/InputComponent";
import { GenericComponent } from "../../../components/GenericComponent";
import { Generico } from "../../../components/Generico";
import { Pep } from "../../../models/fun/Pep";
import { Button } from "primereact/button";
import { FormPep } from "../FormPep";
import { FileBvg } from "../../../components/FileBvg";
import { useToken } from "../../../components/useToken";

export const FuncionarioPep = ({ id, toast, permss, opcion, urlsPep, origen, lista }) => {

    const generic = new GenericComponent();
    const omodel = new Pep();
    const { accionesTbl } = Generico();
    const defaultValues = generic.default(omodel.model, null);
    const { control, formState: { errors }, handleSubmit, reset, setError, getValues } = useForm({ defaultValues });
    let loadLazyTimeout = null;
    const { getPerss } = useToken();
    const funSrv = new FuncionarioService();
    const [registros, setRegistros] = useState(null);
    const [dialogPep, setDialogPep] = useState(false);
    const [dialogFormPep, setDialogFormPep] = useState(false);
    const [dialogSubirPep, setDialogSubirPep] = useState(false);
    const [pep, setPep] = useState(null);
    const { table, setLoading, setTotalRecords, lazyParams, inText } = InputComponent({ registers: registros, omodel, generic, errors, control, permss, id: getValues('id') });

    useEffect(() => {
        loadLazyPep();
    }, [id]);

    const editPep = () => {
        funSrv.consultar(pep?.id, opcion, id).then(res => {
            reset(generic.renewValues(res.data, omodel.model));
            setDialogPep(true);
        });
    }

    const loadLazyPep = () => {
        setLoading(true);

        if (loadLazyTimeout) {
            clearTimeout(loadLazyTimeout);
        }

        let _lazyParams = lazyParams;
        _lazyParams.idp = id;

        loadLazyTimeout = setTimeout(() => {
            funSrv.listado({ lazyEvent: JSON.stringify(_lazyParams) }, opcion).then(res => {
                setTotalRecords(res.data.total);
                setRegistros(res.data.registros);
                setLoading(false);
            });
        });
    }

    const onSubmit = (data) => {
        data.idp = id;
        if (getValues('id') == null) {
            funSrv.nuevo(data, opcion).then(res => {
                setDialogPep(false);
                loadLazyPep();
                generic.funcSuccess(res, toast);
            }).catch(error => {
                generic.funcError(error, toast, setError);
            });
        }
        else {
            funSrv.editar(data, opcion).then(res => {
                setDialogPep(false);
                loadLazyPep();
                generic.funcSuccess(res, toast);
            }).catch(error => {
                generic.funcError(error, toast, setError);
            });
        }
    }

    const openNew = () => {
        setPep(null);
        setDialogPep(true);
        reset(defaultValues);
    }

    const confirmDelete = () => {
        confirmDialog({
            message: '¿Está seguro de eliminar la información?',
            header: 'Confirmación',
            icon: 'pi pi-exclamation-triangle',
            accept: () => deletePep()
        });
    };

    const deletePep = () => {
        funSrv.eliminar(pep?.id, opcion, id).then(res => {
            loadLazyPep();
            generic.funcSuccess(res, toast);
        }).catch(error => {
            generic.funcDeleteError(error, toast);
        });
    }

    const onError = (errors) => {
        toast.current.show({ severity: 'error', summary: 'Error', detail: 'Se detectaron errores, por favor revise el formulario para corregir.', life: 3000 });
    }

    const itemsMenu = () => {
        const items = [];
        if (permss?.actualizar && (!pep?.nemonico || pep.nemonico !== 'FINALIZADO'))
            items.push({ label: 'Editar', icon: 'pi pi-pencil', command: () => { editPep() } });
        if (permss?.crear && !origen && pep?.estado_pep !== 'FINALIZADO' &&
            (!getPerss() || (getPerss() && (pep?.estado_pep === 'BORRADOR' || !pep?.pip))))
            items.push({ label: 'Formulario Pep', icon: 'pi pi-file', command: () => { setDialogFormPep(true) } });
        if (permss?.consultar && pep?.pip)
            items.push({ label: 'Anexos formulario(PEP)', icon: 'pi pi-reddit', command: () => { setDialogSubirPep(true)} });
        if (permss?.eliminar && !lista && (!pep?.nemonico || pep?.nemonico !== 'FINALIZADO'))
            items.push({ label: 'Eliminar', icon: 'pi pi-trash', command: () => { confirmDelete() } });

        return [{ label: 'Opciones', items: items }];
    }

    const headerTemplate = (options) => {
        return (
            <div className={options.className}>
                <div className="flex align-items-center gap-2">
                    {!lista && <Button icon="pi pi-plus" className="p-button-rounded p-button-success mr-2" type="button" onClick={openNew} title="Registrar información"
                        style={{ display: permss?.crear ? 'block' : 'none' }} />}
                    <span className="font-bold">Relación con Personas Expuestas Políticamente y Públicamente</span>
                </div>
            </div>
        );
    }

    const reiniciarPep = () => {
        setDialogFormPep(false);
        loadLazyPep();
    }

    const reiniciar = () => {
        loadLazyPep();
        setDialogSubirPep(false);
    }

    return (
        <>
            <Panel headerTemplate={headerTemplate}>
                {table({ action01: accionesTbl, reg: setPep, items: itemsMenu() })}
            </Panel>

            <Dialog visible={dialogPep} header={getValues('id') == null ? "Nueva Información" : "Editar Información"} modal className="p-fluid modal-ssize"
                onHide={e => setDialogPep(false)} style={{ width: '400px' }}>
                <br />
                <form onSubmit={handleSubmit(onSubmit, onError)}>
                    <div className="formgrid grid">
                        {inText('nombres', {}, 12)}
                        {inText('apellidos', {}, 12)}
                        {inText('cargo', {}, 12)}
                    </div>

                    {generic.buttonsForms(setDialogPep)}
                </form>
            </Dialog>

            <Dialog visible={dialogFormPep} header={"Formulario PEP: " + pep?.nombre_completo} modal className="p-fluid modal-ssize" onHide={e => setDialogFormPep(false)} style={{ maxWidth: '1000px' }}>
                <FormPep tipo="colaboradorBvg" idp={pep?.id} idOrigen={pep?.pip ?? null} permss={permss} toast={toast} reiniciar={reiniciarPep} setDialogClientepn={setDialogFormPep} />
            </Dialog>

            <Dialog visible={dialogSubirPep} header={"Formulario PEP: " + pep?.nombre_completo + (pep?.estado_pep === 'BORRADOR' ?
                '(estado borrador)' : pep?.estado_pep === 'ENVIADO' ? '(ya fue enviado)' : ' (fue finalizado)')}
                modal className="p-fluid modal-ssize" onHide={e => setDialogSubirPep(false)} style={{ width: '800px' }}>
                <FileBvg generic={generic} tipo="colaboradorBvg" urls={urlsPep} idp={pep?.pip} toast={toast} permss={permss} estado={pep?.estado_pep} reiniciar={reiniciar}
                    setDialogSubir={setDialogSubirPep} />
            </Dialog>
        </>
    )
}
